import './App.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCrown,
    faCaretUp,
    faCaretDown,
    faPlus
} from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import {useEffect, useState} from "react";

const baseURL = "/api/leaderboards";

function App() {

  const [leaderboards, setLeaderboards] = useState({data: [ {
      "name": "Ayoub",
      "change": -1000,
      "mmr": 2400,
      "badges": ["golden_time", "silver_trophy"]
  }]});
  const [playlist, setPlaylist] = useState(10);
  const [time, setTime] = useState("daily");

  useEffect(() => {
      let url =  `${baseURL}/${time}/${playlist}`
    axios.get(url).then((response) => {
      setLeaderboards(response.data);
    });
  }, [time, playlist]);
  if (!leaderboards) return null;

  const renderChange = (change) => {
      if (change === 0) return ""
      if (change === -1000) return <div className="rank__change gold"><span></span> <FontAwesomeIcon icon={faPlus} /> </div>
      if (change > 0) return <div className="rank__change green"> <span>{change}</span> <FontAwesomeIcon icon={faCaretUp} /> </div>
      if (change < 0) return <div className="rank__change red"> <span>{`${Math.abs(change)}-`}</span> <FontAwesomeIcon icon={faCaretDown} /> </div>
  }

  const renderBadges = (badges) => {
      return <div className={"badges"}>
          {badges.map(badge => {
              if (badge === "silver_trophy"){
                  return <div className={"badge"}>
                      <i className={"fa-solid fa-trophy silver"}/>
                      <span className="tooltip-text">Player is top 100 in 2 playlists.</span>
                  </div>
              }
              if (badge === "golden_trophy"){
                  return <div className={"badge"}>
                      <i className={"fa-solid fa-trophy golden"}/>
                      <span className="tooltip-text">Player is top 100 in 3 playlists.</span>
                  </div>
              }
              if (badge === "silver_time"){
                  return <div className={"badge"}>
                      <i className={"fa-solid fa-clock silver"}/>
                      <span className="tooltip-text">Player is more than 7 days top 100 in this playlist.</span>
                  </div>
              }
              if (badge === "golden_time"){
                  return <div className={"badge"}>
                      <i className={"fa-solid fa-clock golden"}/>
                      <span className="tooltip-text">Player is more than 30 days top 100 in this playlist.</span>
                  </div>
              }
          })}
      </div>
  }


  return (
    <div className="App">
      <h1><div id="icon"><FontAwesomeIcon icon={faCrown} /></div>RLTop.gg</h1>
        <div>
            <div className="time-selector">
                <div className={`change_button ${time==="daily" ? "selected" : ""}`} onClick={()=>setTime("daily")}>Daily</div>
                <div className={`change_button ${time==="weekly" ? "selected" : ""}`} onClick={()=>setTime("weekly")}>Weekly</div>
                <div className={`change_button ${time==="monthly" ? "selected" : ""}`} onClick={()=>setTime("monthly")}>Monthly</div>
            </div>
            <div className="playlist-selector">
                <div className={`change_button ${playlist===10 ? "selected" : ""}`} onClick={()=>setPlaylist(10)}>1v1</div>
                <div className={`change_button ${playlist===11  ? "selected" : ""}`} onClick={()=>setPlaylist(11)}>2v2</div>
                <div className={`change_button ${playlist===13  ? "selected" : ""}`} onClick={()=>setPlaylist(13)}>3v3</div>
            </div>
        </div>


      <div id="last_updated"><p>Last updated: {Math.floor(((new Date() - new Date(leaderboards.date)) / 60000))} minutes</p></div>
      <div className="leaderboard">
        {
          leaderboards.data.map((player, idx) => {
            return <div className="leaderboard__item">
              { idx===0 || player.mmr === leaderboards.data[0].mmr ? <div className="crown"><FontAwesomeIcon icon={faCrown} /></div> : "" }

              <h3>{player.name}</h3>
              <div className="mmr">{player.mmr}</div>
                {renderBadges(player.badges)}
                {renderChange(player.change)}
            </div>
          })
        }
      </div>
    </div>
  );
}

export default App;
